@use "@styles/partials/functions" as *;

.wrapper {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  gap: 4px;
}

.tag {
  padding-left: spacing(1);
  padding-right: spacing(1);
  border-radius: 3px;
  background-color: var(--contextual-colors-ui-secondary);
  height: 18px;
  color: var(--common-white);
}

.premiumTag {
  font-weight: var(--font-weight-medium);
  background: var(--contextual-colors-label-premium);
}

.astroTVTag {
  background-color: var(--contextual-colors-label-astro-tv);

  &:hover {
    background-color: var(--contextual-colors-label-astro-tv);
  }
}

.newTag {
  background-color: var(--contextual-colors-label-new);

  &:hover {
    background-color: var(--contextual-colors-label-new);
  }
}

.tagLabel {
  padding: 0;
  font-size: rem(12);
  font-weight: var(--font-weight-bold);
  text-transform: capitalize;
  line-height: 100%;
}

.tagIcon {
  margin-right: spacing(0.5);
  margin-left: 0;
  width: 12px;
  color: inherit;
}
